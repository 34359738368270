import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/Coaching/Intro"
import Banner from "../components/Commun/Banner"
import MesCoaching from "../components/Coaching/MesCoaching"
import Price from "../components/Commun/Price"
import Contact from "../components/Commun/Contact"
import Temoignages from "../components/Commun/Temoignages"

const CoachSportifHomme = ({ data, location }) => {
  const prismicData = getPrismicData(data)
  return (
    <Layout kevinFooter={data.kevinFooter.childImageSharp.fixed}>
      <SEO title="Coach Sportif Personnel pour Homme" description="Je vous accompagne dans la réalisation de votre objectif tels que : la perte de poids, la réathlétisation ou la prise de masse. Vous bénéficierez d’un programme personnalisé adapté à votre profil." />
      <Banner
        title={prismicData.banner.title}
        imageBanner={data.banner.childImageSharp.fluid}
        imageHeight="350px"
        imageMobileHeight="350px"
        isfirstBlock
        buttonText="Prendre RDV"
        buttonUrl="/contact"
        marginBottom="1rem"
        isAlignLeft
        objectPosition="center 30% !important"
      />
      <Intro
        titre={prismicData.contenuPrincipal.titre}
        text={prismicData.contenuPrincipal.contenu}
        text2={prismicData.contenuPrincipal.contenu_colonne_2}
        coachingDistance={prismicData.coachingDistance.reassurances}
        coachingNantes={prismicData.coachingNantes.reassurances}
      />
      <MesCoaching
        noFilter
        padding="50px 0"
        items={prismicData.coaching.coachings}
      />
      <Price
        balance={data.balance.childImageSharp.fixed}
        tarifsNantes={prismicData.tarifsNantes.tarifs}
        offresNantesSolo={prismicData.offresNantesSolo.items}
        offresNantesDuo={prismicData.offresNantesDuo.items}
        tarifsDistance={prismicData.tarifsDistance.tarifs}
        uri={location.href}
      />
      <Temoignages items={prismicData.temoignages.temoignages} />
      <Contact />
    </Layout>
  )
}

export default CoachSportifHomme

export const query = graphql`
  query {
    prismicCoachingPourHommeBodyBanniere {
      primary {
        titre {
          html
          text
        }
      }
    }
    prismicCoachingPourHommeBodyCoaching {
      items {
        contenu {
          html
          text
        }
        titre {
          html
          text
        }
        image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        url {
          url
        }
      }
    }
    prismicCoachingPourHommeBodyContenuPrincipal {
      primary {
        contenu {
          html
        }
        contenu_colonne_2 {
          html
        }
        titre {
          html
          text
        }
      }
    }
    prismicCoachingPourHommeBodyDistance {
      items {
        contenu {
          html
          text
        }
      }
    }
    prismicCoachingPourHommeBodyNantes {
      items {
        contenu {
          html
          text
        }
      }
    }
    prismicCoachingPourHommeBodyTarifs {
      items {
        type
        contenu {
          html
          text
        }
        titre {
          html
          text
        }
        sous_titre {
          text
        }
        icone {
          url
          fixed(width: 50, height: 50) {
            ...GatsbyPrismicImageFixed
          }
        }
      }
    }
    prismicCoachingPourHommeBodyOffresNantes {
      primary {
        type
      }
      items {
        prix_e_commerce {
          text
        }
        description_e_commerce {
          text
        }
        id_e_commerce {
          text
        }
        offre_selection {
          text
        }
      }
    }
    prismicCoachingPourHommeBodyOffresNantesSolo {
      primary {
        type
      }
      items {
        prix_e_commerce {
          text
        }
        description_e_commerce {
          text
        }
        id_e_commerce {
          text
        }
        offre_selection {
          text
        }
      }
    }
    prismicCoachingPourHommeBodyTarifsDistance {
      items {
        contenu {
          html
          text
        }
        prix {
          html
          text
        }
        titre {
          html
          text
        }
        sous_titre {
          text
        }
        icone {
          url
          fixed(width: 50, height: 50) {
            ...GatsbyPrismicImageFixed
          }
        }
        description_ecommerce {
          text
        }
        id_ecommerce {
          text
        }
      }
    }
    prismicCoachingPourHommeBodyTemoignageHomme {
      items {
        nom {
          html
          text
        }
        contenu {
          html
          text
        }
        photo {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
    banner: file(relativePath: { eq: "coach-sportif-perso-homme.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 3000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    accroche: file(relativePath: { eq: "accroche.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    balance: file(relativePath: { eq: "icones/scale.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

function getPrismicData(data) {
  return {
    banner: {
      title: data.prismicCoachingPourHommeBodyBanniere.primary.titre.text,
    },
    coaching: {
      coachings: data.prismicCoachingPourHommeBodyCoaching.items,
    },
    contenuPrincipal: {
      titre:
        data.prismicCoachingPourHommeBodyContenuPrincipal.primary.titre.text,
      contenu:
        data.prismicCoachingPourHommeBodyContenuPrincipal.primary.contenu.html,
      contenu_colonne_2:
        data.prismicCoachingPourHommeBodyContenuPrincipal.primary
          .contenu_colonne_2.html,
    },
    coachingDistance: {
      reassurances: data.prismicCoachingPourHommeBodyDistance.items,
    },
    coachingNantes: {
      reassurances: data.prismicCoachingPourHommeBodyNantes.items,
    },
    tarifsNantes: {
      tarifs: data.prismicCoachingPourHommeBodyTarifs.items,
    },
    offresNantesSolo: {
      items: data.prismicCoachingPourHommeBodyOffresNantes.items,
    },
    offresNantesDuo: {
      items: data.prismicCoachingPourHommeBodyOffresNantesSolo.items,
    },
    tarifsDistance: {
      tarifs: data.prismicCoachingPourHommeBodyTarifsDistance.items,
    },
    temoignages: {
      temoignages: data.prismicCoachingPourHommeBodyTemoignageHomme.items,
    },
  }
}
